const regions = [
  {
    "slug": "hokkaido-tohoku",
    "pref": "北海道・東北",
    "imgs": [
      "OT003",
      "OT934",
      "OT814"
    ]
  },
  {
    "slug": "kanto",
    "pref": "関東",
    "imgs": [
      "OT166",
      "ST0047",
      "OT495"
    ]
  },
  {
    "slug": "hokuriku-koshinetsu",
    "pref": "北陸・甲信越",
    "imgs": [
      "OT462",
      "OT526",
      "OT287896"
    ]
  },
  {
    "slug": "tokai",
    "pref": "東海",
    "imgs": [
      "SZ0009",
      "GI0003",
      "AI0036"
    ]
  },
  {
    "slug": "kinki",
    "pref": "近畿",
    "imgs": [
      "HG0001",
      "OS0025",
      "NR0001"
    ]
  },
  {
    "slug": "chuugoku-shikoku",
    "pref": "中国・四国",
    "imgs": [
      "HS0006",
      "OT330778",
      "OY0002"
    ]
  },
  {
    "slug": "kyushu-okinawa",
    "pref": "九州・沖縄",
    "imgs": [
      "OT497",
      "OT201",
      "OT663"
    ]
  }
]

export default regions;