<template>
<div class="relative">
  <svg class="absolute inset-0 w-full h-full" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 81">
  <g :transform="`translate(720, 40.5) rotate(${rotate})`">
    <polygon class="st0" points=" 720, 40 -180,0  720, -40"/>
    <polygon class="st1" points="-720,-25 -180,0 -720,25"/>
  </g>
  </svg>
</div>
</template>
<style scoped>
  .st0 {
    fill: theme('colors.primary');
  }
  .st1 {
    fill: theme('colors.accent');
  }
  .relative {
    margin: -3.125% 0;
  }
  .relative::after {
    content: '';
    display: block;
    padding-bottom: 6.25%;
  }
</style>
<script>
export default {
  props: {
    rotate: {
      type: Number,
      default: 0
    }
  }
}
</script>