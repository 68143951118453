<template>
  <div>
    <div class="flex flex-wrap justify-end">
      <div v-for="item in items" :key="item.slug" class="p-2 w-1/4">
        <a v-if="!!item.slug" :key="item.slug" v-scroll-to="`#warehouses-search-${item.slug}`" class="block rounded-lg overflow-hidden cursor-pointer">
          <div class="imgs" v-if="!!item.imgs">
            <picture class="img" v-for="(img, index) in item.imgs" :key="`img-${img}`" :class="[`img-${index}`, {'show': index==0}]">
              <source :srcset="require(`@/assets/img/index/warehouses/${img}.webp`)" type="image/webp">
              <source :srcset="require(`@/assets/img/index/warehouses/${img}.jpg`)" type="image/jpeg">
              <img :src="require(`@/assets/img/index/warehouses/${img}.jpg`)" alt="" loading="lazy">
            </picture>
          </div>
          <p class="py-2 px-1 text-xs text-black text-center">{{ item.pref }}</p>
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.imgs
  @apply overflow-hidden relative w-full
  &::before
    content: ''
    @apply block
    padding-bottom: 80%
  &::after
    content: ''
    @apply absolute inset-0 z-20 bg-black opacity-0 transition-opacity duration-300
    a:hover &
      opacity: .3
  .img
    @apply absolute inset-0 opacity-0
    transition: opacity 1s ease-in
    &.show
      @apply z-10 opacity-100
    img
      width: 100%
      height: 100%
      object-fit: cover
      object-position: center
      transition: transform .6s ease-in-out, opacity .6s ease-in-out
a
  background-color: #F1F1F1
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.1))
</style>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      imgIndex: 0
    }
  },
  mounted() {
    setTimeout(this.changeImage, 4000);
  },
  watch: {
    imgIndex(v) {
      const imgs = this.$el.querySelectorAll('.img');
      imgs.forEach(img => img.classList.toggle('show', img.classList.contains(`img-${v}`)));
      this.$el.querySelector(`.img-${v}`).addEventListener('transitionend', (e) => {
        setTimeout(this.changeImage, 4000);
      }, {once: true});
    }
  },
  methods: {
    changeImage() {
      this.imgIndex = (this.imgIndex + 1) % 3;
    }
  }
};
</script>
