// https://github.com/ymmooot/nuxt-jsonld/tree/v1.5.3
export default {
  methods: {
    /**
     * 
     * @param { Array<{ head:string, body:string }> } faq 
     * @returns { Object|undefined }
     */
    getFaqJsonld(faq) {
      if (faq && faq.length) {
        const items = [
          ...faq.map((item) => ({
            '@type': 'Question',
            name: item.head,
            acceptedAnswer: {
              '@type': 'Answer',
              text: item.body
            }
          }))
        ]
        return {
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: items,
        }
      }
      return
    },
    /**
     * get jsonld data of breadcrumbs
     * @param { Array<{name:string, url?:string}> } breadcrumbs 
     * @returns { Object|undefined } 
     */
    getBreadcrumbsJsonld(breadcrumbs) {
      if (breadcrumbs && breadcrumbs.length) {
        const items = [
          {
            '@type': 'ListItem',
            position: 1,
            name: this.$t('nav.top'),
            item: this.$seo.url('/'),
          },
          ...breadcrumbs.map((item, index) => ({
            '@type': 'ListItem',
            position: index + 2,
            name: item.name,
            item: item.url && this.$seo.url(item.url),
          }))
        ]
        return {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: items,
        }
      }
      return
    }
  }
}