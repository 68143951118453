<i18n>
  {
    "ja": {
      "h-1": "契約の仕組み",
      "description": "規約の同意後、案件ごとに簡単な個別契約を結ぶだけで全国の倉庫が使えます。\n倉庫提供者との交渉や契約は、すべてWareXが対応します。"
    }
  }
  </i18n>

<template>
  <div class="relative pb-24">
    <div class="bg" />
    <hr class="vertical-hr">
    <div class="relative py-10">
      <div class="text-center w-7/8 mx-auto">
        <h2 class="block-header">{{ $t('h-1') }}</h2>
        <p class="ornament-header">Contract</p>
      </div>

      <p class="md:text-center md:text-sm text-black mb-6 md:mb-12 w-7/8 md:w-full mx-auto whitespace-pre-wrap">
        {{  $t('description') }}
      </p>
      <div
        class="column-item fade"
        :class="{ 'invisible' : !isIntersecting }"
        v-intersect="{
          callback: onIntersect,
          threshold: [0.5]
        }"
      >
        <div class="max-w-4xl w-7/8 md:w-full mx-auto">
          <picture>
            <source srcset="~assets/img/service/SP-WX-FEATURE2.png" media="(max-width: 767px)">
            <img src="~assets/img/service/PC-WX-FEATURE2.png" :alt="$t('services.service2.alt')">
          </picture>
        </div>
      </div>
    </div>
  </div>
</template>

  <style lang="sass" scoped>
  .bg
    @apply absolute inset-0 bg-cover bg-center
    background-color: #C1EBE8
  .vertical-hr
    @apply relative mt-0 -mb-8 border-black
    top: -2rem
  .column-list
    @apply relative
    @screen md
      @apply flex max-w-full mx-auto px-3
      width: 1122px
  </style>

<script>
export default {
  data() {
    return {
      isIntersecting: false,
    }
  },
  methods: {
    onIntersect(entries) {
      this.isIntersecting = entries.isIntersecting || entries.boundingClientRect.top < window.innerHeight * 0.5;
    }
  }
}
</script>
