<i18n>
{
  "ja": {
    "lead": "小規模から大規模まで:様々なお問い合わせをいただいています:",
    "item1": {
      "sub": "毎月のご相談数",
      "main": ":数100:件"
    },
    "item2": {
      "sub": "1案件の実績パレット量",
      "main": ":10〜10,000:まで"
    },
    "item3": {
      "sub": "サービス開始から10ヶ月で",
      "main": "相談数は:約5倍:"
    },
    "casestudy": "導入企業例"
  }
}
</i18n>

<template>
  <div v-intersect="onIntersect">
    <div
      v-swiper:top="swiperOption"
      class="swiper-container pb-3"
    >
      <div class="swiper-wrapper flex items-center">
        <div
          v-for="(item, index) of [...items, ...items]"
          :key="index"
          class="swiper-slide px-2"
        >
          <img :src="getImage(item.img.url)" :alt="item.name" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  &:not(.swiper-container-initialized) {
    opacity: 0
  }
}
</style>

<style lang="sass" scoped>
.container
  @apply overflow-visible transition-opacity duration-200 block
.accent
  @apply mx-auto
  width: 83px
  height: 26px
.lead-text
  @apply w-7/8 mx-auto my-4 text-base text-center font-semibold
  ::v-deep span
    @apply inline-block
.list-item
  @apply relative z-0 mb-4
  height: 90px
  @screen md
    @apply mx-2 mb-0
    width: 240px
.list-image
  @apply absolute top-0 left-0 w-full h-full object-cover
  img
    @apply w-full h-full rounded-xl object-cover
.item-content
  @apply flex flex-col justify-center items-center absolute z-10 top-0 left-0 w-full h-full pt-1 text-white text-center
.item-text
  @apply text-base font-semibold
  ::v-deep span
    @apply text-2xl
.swiper-wrapper
  transition-timing-function: linear
.swiper-slide
  max-width: 180px
</style>

<script>
const swiperOp = {
  slidesPerView: "auto",
  spaceBetween: 40,
  loop: true,
  centeredSlides: true,
  allowTouchMove: false,
  cssMode: true,
  speed: 6000,
  autoplay: {
    delay: 0,
    disableOnInteraction: false,
  },
}
export default {
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    swiperOption() {
      const len = this.items.length
      if (len) {
        return {
          ...swiperOp,
          loopedSlides: len * (len > 10 ? 1 : 2),
        }
      }
      return swiperOp
    }
  },
  methods: {
    onIntersect(entries) {
      const target = entries.target;
      if(entries.isIntersecting) {
        target.classList.add('isIntersect');
      }
    },
    getImage(path) {
      return path ? this.$options.filters.cmsURL(path) : null
    }
  }
};
</script>
