<template>
  <div ref="swiper" v-swiper="swiperOption" instanceName="mainSwiper" class="swiper-main swiper-container w-full mx-auto opacity-0">
    <div ref="swiper-wrapper" class="swiper-wrapper flex items-stretch py-4 md:py-8">
      <template v-for="item in sortedItems">
        <a v-if="!!item.slug" :key="item.slug" :data-target="`warehouses-search-${item.slug}`" class="swiper-slide block w-auto rounded-lg overflow-hidden cursor-pointer">
          <div class="imgs" v-if="!!item.imgs">
            <picture class="img" v-for="(img, index) in item.imgs" :key="`img-${img}`" :class="[`img-${index}`, {'show': index==0}]">
              <source :srcset="require(`@/assets/img/index/warehouses/${img}.webp`)" type="image/webp">
              <source :srcset="require(`@/assets/img/index/warehouses/${img}.jpg`)" type="image/jpeg">
              <img :src="require(`@/assets/img/index/warehouses/${img}.jpg`)" alt="" loading="lazy">
            </picture>
          </div>
          <p class="py-2 px-1 text-xs text-black text-center">{{ item.pref }}</p>
        </a>
      </template>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.imgs
  height: 80px
  @apply overflow-hidden relative w-full
  &::after
    content: ''
    @apply absolute inset-0 z-20 bg-black opacity-0 transition-opacity duration-300
    a:hover &
      opacity: .3
  @screen md
    height: 122px
  .img
    @apply absolute inset-0 opacity-0
    transition: opacity 1s ease-in
    &.show
      @apply z-10 opacity-100
    img
      width: 100%
      height: 100%
      object-fit: cover
      object-position: center
      transition: transform .6s ease-in-out, opacity .6s ease-in-out
.swiper-main
  transition: opacity 0.5s
  > .swiper-wrapper
    > .swiper-slide
      width: 104px
      padding: 0 2px
      @apply relative
      &::after
        content: ''
        @apply absolute inset-0
        z-index: -1
        margin: 0 2px
        background-color: #F1F1F1
        filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.1))
      @screen md
        width: 158px
        padding: 0 4px
        &::after
          margin: 0 4px
</style>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const _this = this;
    return {
      swiperOption: {
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        spaceBetween: 0,
        on: {
          init() {
            _this.$el.classList.remove('opacity-0');
            _this.$el.querySelectorAll('a[data-target]').forEach(element => {
              element.addEventListener('click', e => {
                const target = document.getElementById(e.currentTarget.getAttribute('data-target'));
                scrollBy({
                  left: 0,
                  top: target.getBoundingClientRect().top - 96,
                  behavior: 'smooth'
                })
              })
            })
          }
        }
      },
      imgIndex: 0
    }
  },
  mounted() {
    setTimeout(this.changeImage, 4000);
  },
  computed: {
    sortedItems() {
      const {items} = this;
      const sortedItems =  [
        items[1],
        items[4],
        items[5],
        items[6],
        items[0],
        items[2],
        items[3],
      ]
      return sortedItems;
    }
  },
  watch: {
    imgIndex(v) {
      const imgs = this.$el.querySelectorAll('.img');
      imgs.forEach(img => img.classList.toggle('show', img.classList.contains(`img-${v}`)));
      this.$el.querySelector(`.img-${v}`).addEventListener('transitionend', (e) => {
        setTimeout(this.changeImage, 4000);
      }, {once: true});
    }
  },
  methods: {
    changeImage() {
      this.imgIndex = (this.imgIndex + 1) % 3;
    }
  }
};
</script>
