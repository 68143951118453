<template>
  <div class="flex flex-wrap -m-1">
    <div class="m-1" v-for="(category, index) of categories" :key="index">
      <div v-if="category" class="label"># {{category.name}}</div>
    </div>
  </div>
</template>
<style lang="sass" scoped>
input
  @apply hidden
.label
  @apply bg-gray-200 rounded-full leading-none py-2 px-3 bg-secondary text-white
  font-size: 10px
</style>
<script>
export default {
  props: {
    categories: { type: Array, required: true }
  }
}
</script>
