<i18n>
{
  "ja": {
    "intro": "倉庫探しは",
    "warex": "ウェアエックス",
    "h1": ":あなたの欲しい倉庫が:、:カンタンに見つかる:",
    "doSignUp": {
      "text": "無料登録で",
      "or": "or",
      "warehouse": "倉庫を探す",
      "price": "倉庫料金を見る"
    },
    "cm": "15秒動画をチェック！"
  }
}
</i18n>

<template>
  <div class="-mt-12 lg:-mt-20">
    <div class="relative">
      <div class="bg bg-white" />
      <div class="main-container max-w-screen-xxl mx-auto overflow-hidden">
        <div class="xl:flex xl:justify-end lg:my-8 mx-auto">
          <div class="xl:text-left xl:flex-shrink-0 xl:px-8">
            <h1 class="h1-text">
              倉庫探しは<span class="en text-accent">WareX</span>
              <span class="ja text-accent">ウェアエックス</span>
            </h1>
            <p class="main-lead"><span>全国<b>1400</b>ヶ所以上の倉庫から<br />希望条件に合った倉庫が見つかります</span></p>
            <ul class="main-points">
              <li>
                <span class="icon"><img src="@/assets/img/index/icon-shipment.png" alt=""></span>
                入出荷作業もお任せ</li>
              <li>
                <span class="icon"><img src="@/assets/img/index/icon-transport.png" alt=""></span>
                輸送手配もサポート</li>
              <li>
                <span class="icon"><img src="@/assets/img/index/icon-fast.png" alt=""></span>
                最短３日で利用可能</li>
            </ul>
            <MainRegionsAsSlider class="xl:hidden" :items="warehousesData" />
            <div class="mt-8 -mx-2 md:flex items-center justify-center xl:justify-start">
              <nuxt-link to="/warehouses/search/map/東京都/?distance=50" class="heap-sign-up-button-fv signup-button bg-orange" >
                倉庫をMAPで探す
              </nuxt-link>
              <nuxt-link to="/user-contact" class="heap-warehouse-price-button-fv signup-button bg-secondary">
                無料で相談する
              </nuxt-link>
            </div>
          </div>
          <MainRegions class="hidden xl:block regions" :items="warehousesData" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.bg
  @apply absolute inset-0 bg-cover
  background-position: top 50% right 40%
  background-image: url(~@/assets/img/index/bg_kv.png)
  @screen lg
    @apply bg-right
.bg-gradient
  @apply absolute inset-0 bg-cover
  opacity: 0.8
  background-image: linear-gradient(to bottom, transparent 25%, theme('colors.accent') 100%)
  @screen lg
    @apply bg-right
    background-image: linear-gradient(to right, transparent 25%, theme('colors.accent') 100%)
.main-container
  @apply relative pt-32 pb-8 text-center
.h1-text
  @apply font-sans font-semibold text-center inline-block leading-none text-32p mb-2
  .en
    font-size: 38px
    font-weight: 600
  .ja
    font-size: 10px
    @apply text-right block
  @screen lg
    font-size: 56px
    @apply font-bold mb-2
    .en
      font-size: 150%
      vertical-align: -0.03em
    .ja
      @apply text-sm
.main-lead
  @apply text-lg text-black leading-snug
  span
    background-image: linear-gradient(to bottom, transparent 80%, #B7DDDB 80%)
    @screen xl
      background-image: none
  b
    font-size: 1.3em
    font-weight: 600
  @screen lg
    @apply text-32p
.main-points
  @apply mt-6 -mx-2 inline-block text-black text-left
  @screen md
    @apply flex justify-center items-center mt-6 text-xl
  @screen xl
    @apply justify-start
  li
    @apply mx-2 flex items-center mb-3 whitespace-no-wrap
    @screen md
      @apply mb-0
    .icon
      width: 2.25rem
      @apply mr-2 flex-shrink-0
      @screen md
        @apply w-auto mr-2
      img
        height: 1.5rem
        @apply mx-auto
        @screen md
          height: 1.875rem
        @apply w-auto
.signup-button
  width: 315px
  @apply flex justify-center max-w-full px-8 py-5 mx-auto mb-3 cursor-pointer text-lg text-white font-semibold rounded-full duration-200 transition-shadow
  @screen md
    @apply mx-2 mb-0 text-xl
.regions
  @apply -ml-32
  width: 50%
</style>

<style scoped>
.scroll-bar {
  animation: scroll-bar 2s infinite;
}
@keyframes scroll-bar {
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }
  10% {
    opacity: 1;
    transform: translateY(-20%);
  }
  70% {
    opacity: 1;
    transform: translateY(120%);
  }
  80%, 100% {
    opacity: 0;
    transform: translateY(120%);
  }
}
</style>

<script>
import { getToken } from '@/utils/auth';
import modalMixin from '@/components/mixins/modalHandler';
import MainRegionsAsSlider from "@/components/index/mainRegionsAsSlider";
import MainRegions from "@/components/index/mainRegions";
import warehousesData from '@/components/index/data/regions'

export default {
  mixins: [ modalMixin ],
  components: { MainRegions, MainRegionsAsSlider },
  data() {
    return {
      warehousesData,
      youtubeId: 'b-itdXRRM3E',
    }
  },
  methods: {
    onSearch() {
      if (getToken()) {
        location.href = '/workspace/'
      } else {
        this.openModalDialog();
      }
    }
  }
};
</script>
