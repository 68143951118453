<template>
  <div class="relative pb-8 sm:pb-16">
    <div class="box bg-accent p-8 rounded-xxl flex flex-col h-full">
      <div class="text-4xl font-semibold text-orange">Q.</div>
      <p class="text-xl font-semibold text-white">{{ question }}</p>
      <hr class="my-4 border-white opacity-25" />
      <div class="text-4xl text-white font-semibold">A.</div>
      <div class="md text-sm font-medium text-white leading-loose" v-html="$md.render(answer)" />
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~assets/css/markdown.sass'
</style>

<script>
export default {
  props: {
    question: {
      type: String,
      default: ''
    },
    answer: {
      type: String,
      default: ''
    }
  },
  methods: {
    onIntersect(entries) {
      const target = entries.target;
      if(entries.isIntersecting) {
        target.classList.add('isIntersect');
      }
    }
  }
}
</script>
