<i18n>
{
  "ja": {
    "h-1": "活用事例",
    "lead": "WareXをご利用になった:お客様の活用事例をご紹介いたします。:",
    "button": "活用事例一覧へ"
  }
}
</i18n>

<template>
  <div v-intersect="onIntersect">
    <hr class="vertical-hr">
    <div class="overflow-hidden bg-secondary pt-24 pb-24">
      <div class="text-center w-7/8 mx-auto">
        <h2 class="block-header">{{ $t('h-1') }}</h2>
        <p class="ornament-header">Case Study</p>
        <div class="block-lead" v-html="$md.render($t('lead'))" />
      </div>
      <div
        v-swiper:mySwiper="swiperOption"
        class="swiper-container container w-10/12 mx-auto"
      >
        <div class="swiper-wrapper flex items-stretch pt-12 pb-8">
          <article-card v-for="(article, index) of articles" :key="index"
            class="swiper-slide mx-2 lg:mx-8"
            :article="article"
          />
        </div>
        <div class="swiper-pagination"></div>
      </div>
      <div class="mt-12 text-center">
        <nuxt-link class="inline-flex items-center bg-white text-secondary rounded-full text-sm leading-none py-3 px-6" :to="`/case-study`">{{ $t('button') }}<span class="material-icons ml-2">arrow_forward</span></nuxt-link>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.block-header,
.ornament-header,
.block-lead
  @apply text-white
::v-deep
  span
    @apply inline-block
.swiper-container
  @apply overflow-visible
.swiper-slide
  max-width: 774px
  @apply h-auto
.swiper-pagination
  @apply relative mt-6
  ::v-deep
    .swiper-pagination-bullet
      @apply mx-2
      height: 12px
      width: 12px
      border-radius: 6px
      transition: width 0.5s
      opacity: 1
      &-active
        @apply w-16
      &:not(.swiper-pagination-bullet-active)
        @apply bg-white
        @screen sm
          @apply bg-black
          opacity: 0.2
</style>

<script>
import ArticleCard from "@/components/case-study/articleCard";
export default {
  components: {
    ArticleCard
  },
  props: {
    articles: { type: Array, required: true }
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
          bulletClass: 'swiper-pagination-bullet',
          bulletActiveClass: 'swiper-pagination-bullet-active bg-orange'
        }
      }
    };
  },
  methods: {
    onIntersect(entries) {
      const target = entries.target;
      if(entries.isIntersecting) {
        target.classList.add('isIntersect');
      }
    }
  }
};
</script>
