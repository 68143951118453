<i18n>
{
  "ja": {
    "h1": "全国の倉庫が{newline-sp}すぐに見つかる",
    "leading": "WareXは、倉庫への一括問い合わせから{newline-pc}入出荷管理までできる倉庫利用サービスです。",
    "search": {
      "btn": "検索",
      "label": "希望エリア"
    },
    "doSignUp": {
      "warehouse": "無料登録して倉庫<i class='warehouse'></i>を探す",
      "price": "無料登録して倉庫料金<i class='price'></i>を見る"
    }
  }
}
</i18n>

<template>
  <div class="relative -mt-20" v-intersect="{
      callback: onIntersect,
      threshold: [0.5]
    }">

    <div class="absolute inset-0 bg-black overflow-hidden">
      <div class="video-container"><video loop autoplay muted playsinline>
        <source src="/video/main.mp4" type="video/mp4">
      </video></div>
    </div>

    <div class="overflow-hidden relative pt-32 lg:pt-0">
      <div class="pb-8 md:pb-24 lg:min-h-0 lg:pt-40 lg:pb-16 flex flex-col lg:flex-row justify-center lg:justify-start lg:items-center max-w-6xl w-7/8 mx-auto">
        <div class="mx-auto text-center fade" :class="{'invisible' : !isIntersecting}">
          <h1 class="mx-auto lg:ml-0 text-4xl md:text-5xl text-center font-semibold leading-snug text-white">
            <i18n path="h1" tag="span" class="leading-relaxed border-solid border-b-4 border-primary-dark">
              <template #newline-sp><br class="md:hidden"></template>
            </i18n>
          </h1>
          <i18n path="leading" tag="p" class="text-left md:text-center mt-3 md:mt-6 text-sm md:text-base leading-loose text-white font-semibold">
            <template #newline-pc><br class="hidden md:block"></template>
          </i18n>
          <div class="mt-6 mb-4 text-center">
            <div class="-my-2 -mx-3 flex flex-wrap items-center justify-center">
              <a class="my-2 mx-3 w-full md:w-auto heap-sign-up-button-fv signup-link" @click="openModalDialog">
                <span v-html="$t('doSignUp.warehouse')" />
                <span class="material-icons align-middle ml-2">arrow_forward</span>
              </a>
              <a class="my-2 mx-3 w-full md:w-auto heap-warehouse-price-button-fv signup-link-price" @click="openModalDialog">
                <span v-html="$t('doSignUp.price')" />
                <span class="material-icons align-middle ml-2">arrow_forward</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.video-container
  @apply absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  width: 100vw
  height: 56.25vw
  @media (max-aspect-ratio: 1/1), (max-width:1023px) and (max-aspect-ratio: 16/10)
    width: 178vh
    height: 100vh
  video
    @apply absolute inset-0 w-full h-full
  &::after
    content: ''
    @apply absolute inset-0 bg-black
    opacity: 0.65
.scroll-icon
  @apply absolute text-6xl inset-0 m-auto
.signup-link,
.signup-link-price
  @apply inline-flex items-center justify-center py-3 px-6 leading-none rounded-full text-center cursor-pointer
.signup-link
  @apply border border-orange bg-orange text-white
  ::v-deep i.warehouse
    @apply inline-block bg-contain bg-center
    width: 1.25em
    height: 1.25em
    vertical-align: -0.25em
    background-image: url("~@/assets/img/icon_warehouse_white.svg")
.signup-link-price
  @apply border border-orange bg-white text-orange
  ::v-deep i.price
    @apply inline-block bg-contain bg-center
    width: 1.25em
    height: 1.25em
    vertical-align: -0.25em
    background-image: url("~@/assets/img/icon_price.svg")
.leading
  ::v-deep
    span
      @screen md
        @apply inline-block
</style>

<script>
import modalMixin from '@/components/mixins/modalHandler';
export default {
  mixins: [ modalMixin ],
  data () {
    return {
      isIntersecting: false,
      searchAddress: ""
    }
  },
  methods: {
    onIntersect(entries) {
      this.isIntersecting = entries.isIntersecting;
    },
    goSearch () {
      const { searchAddress } = this;
      if (searchAddress !== '') {
        let query = Object.assign({}, this.$route.query) || {};
        query = { ...query, address: searchAddress, distance: 50 };
        this.$router.push({ path: this.localePath('/warehouses/search/map/'), query: {...query} });
      }
    }
  }
};
</script>