<i18n>
{
  "ja": {
    "h-1": "お知らせ"
  }
}
</i18n>

<template>
  <div v-intersect="onIntersect">
    <hr class="vertical-hr">
    <div class="overflow-hidden bg-gray-100 pt-24 pb-24">
      <div class="text-center w-7/8 mx-auto">
        <h2 class="block-header">{{ $t('h-1') }}</h2>
        <p class="ornament-header">NEWS</p>
      </div>
      <div class="mt-16 flex items-center w-7/8 mx-auto">
        <div class="max-w-4xl mx-auto">
          <div v-for="(news, index) of newsItems" :key="index"
            class="flex flex-col md:flex-row items-center mt-4">
            <div class="order-none my-1 text-sm w-24 flex-shrink-0">{{ news.publishedAt }}</div>
            <div v-if="news.news_category" class="order-first md:order-none my-1 md:w-40 mx-4 flex-shrink-0">
              <div class="inline-block text-xs text-center leading-tight bg-secondary text-white rounded px-2 py-1">{{ news.news_category.name }}</div>
            </div>
            <div class="order-none my-1 text-sm flex-grow">
              <nuxt-link :to="`/news/${news.slug}`">{{ news.title }}</nuxt-link>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    newsItems: {
      type: Array,
      required: true,
    }
  },
  methods: {
    onIntersect(entries) {
      const target = entries.target;
      if(entries.isIntersecting) {
        target.classList.add('isIntersect');
      }
    }
  }
};
</script>
