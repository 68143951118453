<i18n>
{
  "ja": {
    "h-1": "ご利用シーン",
    "lead": "WareXは様々なシーンにおいて:ご活用いただけます。:\n日々の業務で:荷物の預け先に困った際には、: :ぜひお試しください。:",
    "doSignUp": "登録して倉庫料金を見る",
    "f-1": {
      "badge" : "すべてのお客様",
      "head" : "あふれた荷物を保管",
      "leading" : "急な需要による生産や輸入増加時に"
    },
    "f-2": {
      "badge" : "店舗をお持ちのお客様",
      "head" : "引越し荷物を保管",
      "leading" : "引越しや店舗改装など、\n荷物を一時的に避難させたい時に"
    },
    "f-3": {
      "badge" : "輸入EC事業者様",
      "head" : "輸入時の荷物を保管",
      "leading" : "保管料の高いECプラットフォームに全てを預けず\n安くてデバンニング作業もできる中継倉庫として"
    },
    "f-4": {
      "badge" : "全国各地に複数拠点",
      "head" : "輸配送コストを最適化",
      "leading" : "より輸配送コストが削減できる倉庫を\n全国に複数持つことも可能"
    }
  }
}
</i18n>

<template>
  <div v-intersect="onIntersect">
    <div class="overflow-hidden pt-24 pb-24">
      <div class="text-center w-7/8 mx-auto">
        <h2 class="block-header">{{ $t('h-1') }}</h2>
        <p class="ornament-header">Use Case</p>
        <div class="block-lead" v-html="$md.render($t('lead'))" />
      </div>

      <div
        class="hidden lg:block container w-7/8 max-w-4xl mx-auto mt-8"
      >
        <div class="-mx-3 flex flex-row flex-wrap justify-around">
          <usecase-box v-for="index of 4" :key="index"
            class="w-1/2 my-16 px-3"
            :number="index"
            :badge="$t(`f-${index}.badge`)"
            :head="$t(`f-${index}.head`)"
            :leading="$t(`f-${index}.leading`)"
          />
        </div>
      </div>
      <div
        v-swiper:mySwiper="swiperOption"
        class="swiper-container lg:hidden container w-10/12 mx-auto"
      >
        <div v-intersect="onIntersect_sp" class="swiper-wrapper flex items-stretch py-16">
          <usecase-box v-for="index of 4" :key="index"
            class="swiper-slide px-2"
            :lazy="false"
            :number="index"
            :badge="$t(`f-${index}.badge`)"
            :head="$t(`f-${index}.head`)"
            :leading="$t(`f-${index}.leading`)"
          />
        </div>
        <div class="swiper-pagination"></div>
      </div>

      <div class="mt-10 lg:mt-0 w-7/8 mx-auto">
        <div class="text-center relative md:flex md:justify-center -mx-2">
          <nuxt-link to="/warehouses/search/map/東京都/?distance=50" class="heap-sign-up-button-fv signup-button bg-orange" >
            倉庫をMAPで探す
          </nuxt-link>
          <nuxt-link to="/user-contact" class="signup-button bg-secondary">
            無料で相談する
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.swiper-container
  @apply overflow-visible
.swiper-slide
  @apply h-auto
  @screen sm
    @apply w-1/2
.swiper-pagination
  @apply relative -mt-6
  ::v-deep
    .swiper-pagination-bullet
      @apply mx-2
      height: 12px
      width: 12px
      border-radius: 6px
      transition: width 0.5s
      &-active
        @apply w-16
.signup-button
  width: 315px
  @apply flex justify-center max-w-full px-8 py-5 mx-auto mb-3 cursor-pointer text-lg text-white font-semibold rounded-full duration-200 transition-shadow
  @screen md
    @apply mx-2 mb-0 text-xl
</style>

<script>
import { getToken } from '@/utils/auth';
import modalMixin from '@/components/mixins/modalHandler';
import SectionDivider from "@/components/index/sectionDivider";
import usecaseBox from "@/components/index/usecaseBox";
export default {
  mixins: [ modalMixin ],
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
          bulletClass: 'swiper-pagination-bullet',
          bulletActiveClass: 'swiper-pagination-bullet-active bg-secondary'
        }
      },
      isIntersecting: false
    };
  },
  components: {
    SectionDivider,
    usecaseBox
  },
  methods: {
    onIntersect(entries) {
      const target = entries.target;
      if(entries.isIntersecting) {
        target.classList.add('isIntersect');
      }
    },
    onIntersect_sp(entries) {
      this.isIntersecting = entries.isIntersecting || entries.boundingClientRect.top < window.innerHeight * 0.5;
    },
    onSearch() {
      if (getToken()) {
        location.href = '/workspace/'
      } else {
        this.openModalDialog();
      }
    }
  }
};
</script>
