<i18n>
{
  "ja": {
    "title": "倉庫を探す",
    "description": "WareXに登録されている全国の倉庫を探すことができます。",
    "lead": "無料登録すると、検索に加えて、:空きの一括確認や入出荷作業もできます:",
    "doSignUp": {
      "price": "登録して倉庫料金を見る"
    },
    "areas": {
      "hokkaido-tohoku" : "北海道・東北",
      "kanto" : "関東",
      "hokuriku-koshinetsu" : "北陸・甲信越",
      "tokai" : "東海",
      "kinki" : "近畿",
      "chuugoku-shikoku" : "中国・四国",
      "kyushu-okinawa" : "九州・沖縄"
    },
    "hokkaido-tohoku-prefectures" : {
      "hokkaido" : "北海道",
      "aomori" : "青森県",
      "iwate" : "岩手県",
      "miyagi" : "宮城県",
      "akita" : "秋田県",
      "yamagata" : "山形県",
      "fukushima" : "福島県"
    },
    "kanto-prefectures" : {
      "ibaraki" : "茨城県",
      "tochigi" : "栃木県",
      "gunma" : "群馬県",
      "saitama" : "埼玉県",
      "chiba" : "千葉県",
      "tokyo" : "東京都",
      "kanagawa" : "神奈川県"
    },
    "hokuriku-koshinetsu-prefectures" : {
      "niigata" : "新潟県",
      "toyama" : "富山県",
      "ishikawa" : "石川県",
      "fukui" : "福井県",
      "yamanashi" : "山梨県"
    },
    "tokai-prefectures" : {
      "gifu" : "岐阜県",
      "shizuoka" : "静岡県",
      "aichi" : "愛知県",
      "mie" : "三重県"
    },
    "kinki-prefectures" : {
      "shiga" : "滋賀県",
      "kyoto" : "京都府",
      "osaka" : "大阪府",
      "nara" : "奈良県",
      "hyogo" : "兵庫県"
    },
    "chuugoku-shikoku-prefectures" : {
      "shimane" : "鳥取県",
      "hiroshima" : "広島県",
      "okayama" : "岡山県",
      "yamaguchi" : "山口県",
      "kagawa" : "香川県",
      "ehime" : "愛媛県"
    },
    "kyushu-okinawa-prefectures" : {
      "fukuoka" : "福岡県",
      "saga" : "佐賀県",
      "nagasaki" : "長崎県",
      "kumamoto" : "熊本県",
      "oita" : "大分県",
      "miyazaki" : "宮崎県",
      "okinawa" : "沖縄県"
    },
    "howToUse": {
      "step1": "全国から\n倉庫を検索",
      "step2": "倉庫の空きを\n一括確認",
      "step3": "入出荷などの\nオペレーション"
    }
  }
}
</i18n>

<template>
  <div id="warehouses-search" class="pt-16 pb-8 relative overflow-hidden">
    <div class="overflow-hidden relative">
      <div class="text-center w-7/8 mx-auto">
        <h2 class="block-header">{{ $t('title') }}</h2>
        <p class="ornament-header">Search</p>
      </div>
    </div>

    <p class="my-6 w-7/8 mx-auto xl:hidden">{{ $t('description') }}</p>

    <div class="flex justify-center -mr-6">
      <div class="mt-4 mb-8 text-left w-7/8 md:w-auto mx-auto relative">
        <div class="pt-6 w-full flex" v-for="(area, name, index) in $t('areas')" :key="index" :id="`warehouses-search-${name}`">
          <div class="area-name font-bold md:mr-4 md:pr-6 inline-block leading-loose">{{ area }}</div>
          <div class="area-links flex flex-wrap align-top leading-loose">
            <nuxt-link class="font-sm cursor-pointer underline text-success pr-4 md:pr-6" v-for="(prefecture, namePref, indexPref) in $t(name + '-prefectures')" :key="indexPref" :to="localePath('/warehouses/search/area/' + prefecture)">
              <span class="whitespace-no-wrap">{{ prefecture }}</span>
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.lead-text
  @apply relative w-7/8 mx-auto my-6 text-base text-center
  ::v-deep span
    @apply inline-block
.area-links
  width: calc(100% - 120px)
  > a
    vertical-align: super
.area-name
  min-width: 125px
  vertical-align: sub
.bg
  @apply absolute inset-0 bg-cover bg-bottom
  background-image: url(~@/assets/img/index/main-bg-map.png)
.arrow-icon
  @apply mx-auto
  width: 23px
  height: 33px
.steps
  @apply relative flex justify-center items-start w-7/8 mx-auto
.steps-item
  @apply mb-12 text-center w-1/3 px-4
  @screen md
    @apply mb-0 mx-3 px-0
    width: 150px
.signup-button
  width: 315px
  @apply flex justify-center max-w-full px-8 py-5 mx-auto mb-3 cursor-pointer text-lg text-white font-semibold rounded-full duration-200 transition-shadow
  @screen md
    @apply mx-2 mb-0 text-xl
</style>

<script>
import { getToken } from '@/utils/auth';
import modalMixin from '@/components/mixins/modalHandler';

export default {
  mixins: [ modalMixin ],
  methods: {
    onSearch() {
      if (getToken()) {
        location.href = '/workspace/'
      } else {
        this.openModalDialog();
      }
    }
  }
}
</script>