<i18n>
{
  "ja": {
    "h-1": "特徴",
    "f-1": {
      "head": "全国の倉庫に\nすぐアプローチ",
      "leading": "WareXのスタッフのサポート付きで最適な倉庫を探せます。"
    },
    "f-2": {
      "head": "契約締結まで\n無料",
      "leading": "成約に至った場合のみ、倉庫利用に関する料金が発生いたします。"
    },
    "f-3": {
      "head": "輸配送や\n流通加工も可能",
      "leading": "保管だけでなく、貨物の輸配送や検品、梱包、デバンニング作業も対応できる倉庫をお探しいたします。"
    }
  }
}
</i18n>

<template>
  <div class="relative mt-8 pb-24">
    <div class="bg" />
    <hr class="vertical-hr">
    <div class="relative py-10">
      <div class="text-center w-7/8 mx-auto">
        <h2 class="block-header">{{ $t('h-1') }}</h2>
        <p class="ornament-header">Service</p>
      </div>
    </div>

    <div class="column-list">
      <feature-box
        v-for="index of 3"
        :key="index"
        :number="index"
        :head="$t(`f-${index}.head`)"
        :subhead="$md.render($t(`f-${index}.subhead`))"
        :leading="$md.render($t(`f-${index}.leading`))"
      />
    </div>
  </div>
</template>

<style lang="sass" scoped>
.bg
  @apply absolute inset-0 bg-cover bg-center
  background-image: url(~@/assets/img/index/main-bg-service.png)
.vertical-hr
  @apply relative mt-0 -mb-8 border-black
  top: -2rem
.column-list
  @apply relative
  @screen md
    @apply flex max-w-full mx-auto px-3
    width: 1122px
</style>

<script>
import FeatureBox from "./featureBox";
export default {
  components: {
    FeatureBox
  }
};
</script>
