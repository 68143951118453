<template>
  <div
    class="column-item fade"
    :class="{ 'invisible' : !isIntersecting }"
    v-intersect="{
      callback: onIntersect,
      threshold: [0.5]
    }"
  >
    <div class="flex items-center justify-center h-10 mb-4">
      <img :src="images[number].icon" alt="">
    </div>
    <h3 class="text-white text-4xl md:text-2xl lg:text-4xl text-center font-semibold leading-tight" v-html="$md.renderInline(head)" />
    <div class="my-2 px-2">
      <picture class="mx-auto">
        <source :srcset="images[number].srcset">
        <img :src="images[number].src" class="mx-auto" alt="">
      </picture>
    </div>
    <div class="px-10 text-base md:text-sm lg:text-base text-justify leading-normal" v-html="leading" />
  </div>
</template>

<style lang="sass" scoped>
.column-item
  @apply flex-1 bg-secondary pb-10 text-white
  margin-top: 4px
  padding-top: 1.875rem
  @screen md
    @apply mx-3 mt-0 rounded-xl
</style>

<script>
const images = ['1', '2', '3'].reduce((list, number) => {
  list[number] = {
    icon: require(`@/assets/img/index/feature-icon${number}.svg`),
    src: require(`@/assets/img/index/feature${number}.png`),
    srcset: [
      require(`@/assets/img/index/feature${number}.png`) + ' 1x',
      require(`@/assets/img/index/feature${number}@2x.png`) + ' 2x'
    ].join(', ')
  }
  return list
}, {})

export default {
  props: {
    number: {
      type: Number,
      default: '1'
    },
    head: {
      type: String,
      default: ''
    },
    subhead: {
      type: String,
      default: ''
    },
    leading: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isIntersecting: false,
      images,
    }
  },
  methods: {
    onIntersect(entries) {
      this.isIntersecting = entries.isIntersecting || entries.boundingClientRect.top < window.innerHeight * 0.5;
    }
  }
}
</script>
