<i18n>
{
  "ja": {
    "h-1": "よくある質問"
  }
}
</i18n>

<template>
  <div v-if="items.length" v-intersect="onIntersect">
    <hr class="vertical-hr">
    <div class="overflow-hidden bg-gray-100 pt-24 pb-24">
      <div class="text-center w-7/8 mx-auto">
        <h2 class="block-header">{{ $t('h-1') }}</h2>
        <p class="ornament-header">FAQ</p>
      </div>
      <div
        v-swiper:mySwiper="swiperOption"
        class="swiper-container container w-10/12 mx-auto"
      >
        <div class="swiper-wrapper flex items-stretch pt-12 pb-8">
          <qa v-for="(qa, index) of items" :key="index"
            class="swiper-slide px-2 lg:px-6"
            :question="qa.head"
            :answer="qa.body"
          />
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
  ::v-deep
    span
      @apply inline-block
  .swiper-container
    @apply overflow-visible
  .swiper-slide
    @apply h-auto
    max-width: 23rem
  .swiper-pagination
    @apply relative -mt-6
    ::v-deep
      .swiper-pagination-bullet
        @apply mx-2
        height: 12px
        width: 12px
        border-radius: 6px
        transition: width 0.5s
        &-active
          @apply w-16
</style>

<script>
import qa from "@/components/index/qa";
export default {
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
          bulletClass: 'swiper-pagination-bullet',
          bulletActiveClass: 'swiper-pagination-bullet-active bg-primary-dark sm:bg-orange'
        }
      }
    };
  },
  components: {
    qa
  },
  methods: {
    onIntersect(entries) {
      const target = entries.target;
      if(entries.isIntersecting) {
        target.classList.add('isIntersect');
      }
    }
  }
};
</script>
