<i18n>
{
  "ja": {
    "description": "WareXは、価格・場所などの条件を踏まえた倉庫検索から入出荷の進捗までチームで一元管理できるシェアリング倉庫サービスです。見積り不要・従量課金・設備や人の手配不要・入出荷管理・チームでの一元管理という5つの特徴で倉庫利用をサポートします。"
  }
}
</i18n>

<template>
  <div>
    <index-main v-if="mainToggle"></index-main>
    <index-main-pre v-else></index-main-pre>
    <feature-toggle name="search">
      <index-warehouses></index-warehouses>
    </feature-toggle>
    <feature-toggle name="customers">
      <index-customers :items="customers"></index-customers>
    </feature-toggle>
    <index-features></index-features>
    <index-contract></index-contract>
    <index-usecase></index-usecase>
    <feature-toggle name="faq">
      <index-faq v-if="faqs && faqs.length" :items="faqs"></index-faq>
    </feature-toggle>
    <feature-toggle name="case-studies">
      <index-case-study :articles="caseStudies"></index-case-study>
    </feature-toggle>
    <feature-toggle name="news">
      <index-news id="news" :news-items="newsItems"></index-news>
    </feature-toggle>
    <MCLogiBanner />
  </div>
</template>

<script>
import JsonldMixin from '@/components/mixins/jsonld'
import IndexMain from "@/components/index/main"
import IndexMainPre from "@/components/index/mainPre"
import IndexCustomers from "@/components/index/customers"
import IndexCaseStudy from "@/components/index/caseStudy"
import IndexFeatures from "@/components/index/features"
import IndexContract from "@/components/index/contract"
import IndexUsecase from "@/components/index/usecase"
import IndexFaq from "@/components/index/faq"
import IndexNews from "@/components/index/news"
import indexWarehouses from "@/components/index/warehouses"
import MCLogiBanner from "@/components/index/mclogiBanner";
import { isValid as isValidString } from '@/components/utils/string'

export default {
  mixins: [JsonldMixin],
  components: {
    IndexMain,
    IndexMainPre,
    IndexCustomers,
    IndexCaseStudy,
    IndexFeatures,
    IndexContract,
    IndexUsecase,
    IndexFaq,
    IndexNews,
    indexWarehouses,
    MCLogiBanner
  },
  async asyncData({ $strapi, app, store }) {
    let customers = [], newsItems = [], caseStudies = [], faqs = []
    let global = store.getters["cms/global"]
    let homepage = store.getters["cms/homepage"]

    try {
      customers = await $strapi.find("customers")
      newsItems = await $strapi.find("news-items", [['_limit', 5]])
      caseStudies = await $strapi.find("case-studies", [['_limit', 5]])
      customers = customers.data
      newsItems = newsItems.data
      caseStudies = caseStudies.data
      await store.dispatch("cms/faqs")
      faqs = store.getters["cms/faqs"].filter(e => e.homepage)
    } catch(e) {
      app.$bugsnag.notify(e)
    }

    const mainToggle = Date.now() >= Date.parse('2022-06-18T00:00:00+09:00')

    return {
      customers,
      newsItems,
      caseStudies,
      faqs,
      global,
      homepage,
      mainToggle
    }
  },
  head() {
    const { seo } = this.homepage;
    const { defaultSeo, siteName } = this.global;
    const fullSeo = {
      ...defaultSeo,
      ...seo,
      metaKeywords: this.$t('keywords')
    };
    const title = isValidString(fullSeo.metaTitle) && `${fullSeo.metaTitle}` || ''
    const sitename = isValidString(siteName) && siteName || ''
    const titleTemplate = `${title}${title && sitename ? '｜' : ''}${sitename}`
    return {
      ...(titleTemplate && { titleTemplate }),
      meta: this.$options.filters.cmsMetaTags(fullSeo)
    }
  },
  jsonld() {
    const faq = this.getFaqJsonld(this.faqs)
    const logo = require('@/assets/img/logo_gaussy.svg')
    const ret = [{
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "Gaussy 株式会社",
      "url": "https://www.gaussy.com/",
      "logo": this.$seo.url(logo),
      "address": {
        "@type": "PostalAddress",
        "addressLocality": "千代田区",
        "addressRegion": "東京都",
        "postalCode": "105-0012",
        "streetAddress": "港区芝大門2-1-16 +SHIFT SHIBADAIMON 8階",
        "addressCountry": "JP"
      }
    }]
    faq && ret.push(faq)
    return ret
  }
};
</script>
