<template>
<nuxt-link class="block rounded-xl overflow-hidden duration-300 transition-shadow hover:shadow-panel" :to="`/case-study/${article.slug}`">
  <div class="h-full md:flex card rounded-xl overflow-hidden bg-white">
    <div class="thumbnail md:w-4/12" :style="{'background-image' : `url(${thumbnailURL})`}"></div>
    <div class="md:w-8/12 p-6 flex flex-col">
      <div>
        <categories :categories="[...article.jobs, ...article.issues]" />
      </div>
      <p class="mt-4 text-lg font-medium">{{article.title}}</p>
      <div class="my-4 text-xs hidden lg:block">{{article.description}}</div>
      <div class="mt-4 md:mt-auto flex items-center">
        <div class="text-sm leading-none">{{article.client}}</div>
        <div class="ml-auto text-xs">{{article.publishedAt}}</div>
      </div>
    </div>
  </div>
</nuxt-link>
</template>
<style lang="sass" scoped>
.card
   min-height: 13.75rem
.thumbnail
  @apply bg-black bg-cover bg-center bg-no-repeat
  padding-bottom: 75%
  @screen md
    padding-bottom: 0
</style>
<script>
import Categories from '@/components/blog/article/categories';
export default {
  components: {
    Categories
  },
  props: {
    article: { type: Object },
  },
  computed: {
    thumbnailURL() {
      let path = null
      if (this.article.thumbnail) {
        path = this.article.thumbnail.formats.small ? this.article.thumbnail.formats.small.url : this.article.thumbnail.url
      }
      return path ? this.$options.filters.cmsURL(path) : null;
    }
  }
}
</script>