<template>
  <div
    class="fade-up"
    :class="{ 'isIntersecting' : isIntersecting, 'invisible' : !isIntersecting }"
    v-intersect="{
      callback: onIntersect,
      threshold: [0.2]
    }"
  >
    <div class="bg-gray-100 pt-12 pb-2 rounded-xxl h-full">
      <div class="flex flex-col items-center justify-center -mt-24 -mx-8 pt-2">
        <span class="inline-block text-6xl font-normal leading-none text-accent">0{{number}}</span>
        <span
          class="inline-flex mt-3 bg-gray-700 text-white text-sm lg:text-base leading-none py-2 px-5 rounded-full"
        >{{ badge }}</span>
      </div>
      <h3 class="my-4 px-4 text-2xl font-semibold leading-tight text-center">
        <span
          v-for="(item, index) in head.split('\n')"
          :key="index"
          class="inline-block"
        >{{item}}</span>
      </h3>
      <div class="illust px-8 lg:px-12">
        <img :src="images[number]" class="mx-auto" />
      </div>
      <p class="my-8 px-8 text-sm leading-relaxed text-center text-gray-800 lg:whitespace-pre-wrap"
      >
        <span
          v-for="(item, index) in leading.split('\n')"
          :key="index"
          :class="item == '' ? 'block h-0' : 'inline-block'"
        >{{item}}</span>
      </p>
    </div>
  </div>
</template>

<style lang="sass" scoped>
p ::v-deep
  span
    @apply inline
    @screen lg
      @apply inline-block
@media screen
  .illust
    transform: scale(0)
    transition: transform 0.5s
    .isIntersecting &
      transform: scale(1)
</style>

<script>
const images = ["1","2","3", "4"].reduce((list, number) => {
  list[number] = require(`@/assets/img/index/usecase${number}.svg`)
  return list
}, {})


// TODO: validate props - check if images[number] exists
export default {
  props: {
    number: {
      type: Number,
      default: '1'
    },
    badge: {
      type: String,
      default: ''
    },
    head: {
      type: String,
      default: ''
    },
    leading: {
      type: String,
      default: ''
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      images,
      isIntersecting: !this.lazy
    }
  },
  methods: {
    onIntersect(entries) {
      if(!this.lazy) return;
      this.isIntersecting = entries.isIntersecting || entries.boundingClientRect.top < window.innerHeight * 0.5;
    }
  }
}
</script>
